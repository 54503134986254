@import '/styles/config.scss';

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($medium-gray, 0.8);
    z-index: 10;
    padding: 0;
    // margin-top: -$header-height;
    @media (max-width: $max-desktop) {
        margin-top: -$header-height-mobile;
    }
    overflow-y: auto;
}

.container {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.modal {
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    position: relative;
    height: fit-content;
    padding: scale-to-em(48);
    width: 400px;

    @media (max-width: 400px) {
        padding: scale-to-em(24);
    }
}

.closeBtn {
    top: scale-to-em(21);
    right: scale-to-em(21);
    position: absolute;
    opacity: 50%;
    filter: invert(25%) sepia(36%) saturate(714%) hue-rotate(167deg)
        brightness(95%) contrast(92%);

    :hover {
        cursor: pointer;
    }

    svg {
        transform: scale(1.4);
    }
}

.radioBtns {
    margin-top: scale-to-em(20);
}

.icons {
    display: flex;
    gap: 15px;

    // justify-content: space-between;
}

.buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: $max-mobile) {
        flex-wrap: wrap;
        justify-content: center;

        button {
            width: 100%;
        }
    }
}

.button {
    position: relative;
    min-width: 120px;
    width: 180px;
}
