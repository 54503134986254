@import '/styles/config.scss';

.grid-item {
    @include grid-item(5, 2);

    @media (max-width: $max-desktop) {
        @include grid-item(7, 0);
        row-gap: scale-to-em(40);
    }

    @media (max-width: $max-tablet) {
        @include grid-item(12, 0);
        margin-right: scale-to-em(20);
    }
}

.text {
    composes: grid-item;
}

.title {
    margin-bottom: scale-to-em(16);
}

.textBlock {
    @include responsive-font(18, 20);
    margin: 0px;
}

.rightImageContainer {
    @include grid-item(4, 8);
    grid-row: 1 / span 3;

    @media (max-width: $max-tablet) {
        @include grid-item(5, 7);
        display: none;
    }

    .rightImage {
        width: calc(100% + var(--container-gutter));
        height: auto;
        max-width: none;
        right: 0;
    }
}

.bottomImageContainer {
    @include grid-item(7, 0);
    direction: rtl;
    margin-top: scale-to-em(100);

    @media (max-width: $max-tablet) {
        @include grid-item(12, 0);
        margin: scale-to-em(20);
    }

    @media (max-width: $max-mobile) {
        @include grid-item(12, 0);
    }

    .bottomImage {
        width: calc(100% + var(--container-gutter));
        height: auto;
        max-width: none;
        right: 0;
    }
}
