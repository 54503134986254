@import '/styles/config.scss';

.card {
    &.size1 {
        // margin: scale-to-em(128) auto;
        align-items: center;
        cursor: pointer;

        @media (max-width: $max-desktop) {
            row-gap: scale-to-em(40);
            // margin: scale-to-em(50) auto;
        }

        // position of image
        .l {
            @include grid-item();
            justify-self: center;
            @media (min-width: $min-desktop) {
                justify-self: flex-start;

                @include grid-item(5, 1);
            }
        }

        .r {
            @include grid-item();
            justify-self: center;
            @media (min-width: $min-desktop) {
                justify-self: flex-end;
                @include grid-item(5, 6);
            }
        }

        // position of text
        .d_l {
            @include grid-item();
            @media (min-width: $min-desktop) {
                @include grid-item(4, 7);
            }
        }

        .d_r {
            @include grid-item();
            @media (min-width: $min-desktop) {
                @include grid-item(4, 1);
                grid-row: 1;
            }
        }
    }

    &__image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $light-gray;
    }
    &.size4 {
        width: 100%;
        &:hover {
            cursor: pointer;
        }

        .text {
            margin-top: scale-to-em(28);

            & > p:nth-child(2) {
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        .aspectRatio {
            width: 100%;
            height: auto;
            position: relative;
            // overflow: hidden;
            padding-top: 100%; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */

            div {
                opacity: 0;
                transition: all 0.6s $ease-out-cubic;
                transform: scale(0.5);
            }
        }
        &:hover {
            .aspectRatio {
                div {
                    opacity: 0.2;
                    transform: scale(0.7);
                }
            }
        }

        @media (max-width: $max-mobile) {
            height: auto;
        }
    }

    &.size3 {
        width: 100%;
        cursor: pointer;

        .text {
            text-align: center;
            margin: 2em;
        }

        .aspectRatio {
            // border-radius: 5px;
            width: 100%;
            height: auto;
            position: relative;
            overflow: hidden;
            padding-top: 69.44%; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */
            border-radius: 8px;
            img {
                transform: scale(1);
                transition: all 0.3s ease-in-out;
            }
        }

        @media (pointer: fine) {
            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.dynamicCard {
    composes: card;
    &.size4 {
        min-height: 450px;
        height: auto;
    }
}

.section {
    @include section(
        $padding-top: $section-padding,
        $padding-bottom: $section-padding * 1.8,
        $padding-top-mobile: $section-padding-mobile,
        $padding-bottom-mobile: $section-padding-mobile
    );

    section + & {
        padding-top: 0;
    }
}

.shortText {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media (max-width: $max-tablet) {
        -webkit-line-clamp: 2;
    }
    // Max height because it was glitchy on ipad
    max-height: scale-to-em(145);
    p {
        margin-top: 0px;
    }
}

.scrapeText {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media (max-width: $max-tablet) {
        -webkit-line-clamp: 2;
    }
    // Max height because it was glitchy on ipad
    max-height: scale-to-em(145);
    p {
        margin-top: 0px;
    }
    font-style: italic;
    margin-bottom: 14px !important;
}
