@import '/styles/config.scss';

.textBlockContainer {
}

.noPadd {
    padding: 0;
}

.grid-item {
    // border: solid red 2px;
    @include grid-item(7, 2);

    @media (max-width: $max-desktop) {
        @include grid-item(12, 0);
    }
}

.title {
    composes: grid-item;
}

.textBlock {
    composes: grid-item;
    @include responsive-font(18, 20);
    margin: 0;

    li {
        margin: 0 0 scale-to-em(12) scale-to-em(32);
    }
}

.buttons {
    composes: grid-item;
}
