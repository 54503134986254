@import 'styles/config';

.grid {
    @include grid(4, $grid-row-gap: 4em);

    @media (max-width: $max-tablet + 180px) {
        @include grid(2);

        row-gap: 1.5em !important;
    }

    @media (max-width: $max-mobile) {
        @include grid(1);
        row-gap: 1.5em !important;
    }
}
