@import '/styles/config.scss';

.media {
    @include grid-item(7, 2);
    border-radius: 8px;
    overflow: hidden;
    iframe {
        width: 100%;
        aspect-ratio: 16/9;
    }

    @media (max-width: $max-desktop) {
        @include grid-item(12, 0);
    }
}

.section {
    padding-bottom: 40px;
    @media (max-width: $max-desktop) {
        padding-bottom: 45px;
    }
    display: flex;
    flex-direction: column;
}

.imageText {
    @include grid-item(7, 2);
    @media (max-width: $max-desktop) {
        @include grid-item(12, 0);
    }
    padding-top: scale-to-em(8);
    height: 30px;
}
