@import '/styles/config.scss';
@import 'styles/config';

.smallPadd {
    padding-top: 32px !important;
    padding-bottom: 32px;
}

.paragraph {
    @include common-grid-item();
    @include responsive-font(18, 20);
}

.button {
    @include common-grid-item();
}
