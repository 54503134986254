@import '/styles/config.scss';

.vimeoContainer {
    @include common-grid-item();
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.placeholder {
    position: absolute;
    z-index: 1;
    width: 100%;
    object-fit: contain;
}

.mainButton {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;

    @media (hover: hover) and (pointer: fine) {
        .play:hover {
            .triangle {
                height: scale-to-em(30);
                width: scale-to-em(30);
            }

            .circle {
                height: scale-to-em(110);
            }
        }
    }
}

.vimeo {
    z-index: 0;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    border-radius: 8px;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.play {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .circle {
        position: absolute;
        top: 0px;
        left: 0px;
        height: scale-to-em(100);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 300ms var(--default-ease);
    }

    .triangle {
        height: scale-to-em(25);
        transition: all 300ms var(--default-ease);
    }
}
