@import '/styles/config.scss';

.container {
    @include common-grid-item;
    display: flex;
    flex-wrap: wrap;
    gap: 0 scale-to-em(14);
    a,
    button {
        margin: 0px;
        height: auto;
    }
}

.diver {
    display: flex;
    gap: scale-to-em(20);
}

.line {
    height: 10px;
    width: 2px;
    align-self: center;
    background: #ccc;
}
