@import '/styles/config.scss';

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba($medium-gray, 0.8);
    z-index: 10;
    padding: 0;
    margin-top: -$header-height;
    @media (max-width: $max-desktop) {
        margin-top: -$header-height-mobile;
    }
    overflow-y: auto;
}

.funeralBG {
    composes: background;
    margin-top: 0 !important;
    top: 0 !important;
}

.container {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.modal {
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    position: relative;
    height: fit-content;
    padding: scale-to-em(48);
    width: 400px;

    @media (max-width: 400px) {
        padding: scale-to-em(24);
    }
}

.modal_big {
    composes: modal;
    width: 100%;
    max-width: 1200px;
    padding: scale-to-em(80) scale-to-em(48) !important;

    @media (max-width: 949px) {
        padding: scale-to-em(60) scale-to-em(48) !important;
    }

    @media (max-width: $max-tablet) {
        padding: scale-to-em(48) scale-to-em(32) !important;
    }
}

.modalInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;

    img {
        max-width: 600px;
        width: 100%;
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }

    & > div:first-child {
        padding: 0 1em;
    }

    @media (max-width: 949px) {
        display: flex;
        flex-direction: column-reverse;

        & > div:first-child {
            padding: 1em;
        }
    }

    @media (max-width: $max-mobile) {
        // img {
        //     display: none;
        // }
        & > div:first-child {
            padding: 0;
        }
    }

    // img {
    //     min-width: 300px;
    //     align-self: center;
    // }
}

.closeBtn {
    top: scale-to-em(21);
    right: scale-to-em(21);
    position: absolute;
    opacity: 50%;
    filter: invert(25%) sepia(36%) saturate(714%) hue-rotate(167deg)
        brightness(95%) contrast(92%);

    :hover {
        cursor: pointer;
    }

    svg {
        transform: scale(1.4);
    }
}

.radioBtns {
    margin-top: scale-to-em(20);
}

.icons {
    display: flex;
    gap: 15px;

    // justify-content: space-between;
}

.buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: $max-mobile) {
        flex-wrap: wrap;
        justify-content: center;

        button {
            width: 100%;
        }
    }
}

.button {
    position: relative;
    min-width: 120px;
    width: 180px;
}

.loadModal {
    .loader {
        align-self: center;
    }

    .smallText {
        margin-top: scale-to-em(16);
    }
}

.loadHeader {
    margin-bottom: scale-to-em(16);
}

.loader {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    -o-animation: spin 2s linear infinite;
    -ms-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
.loaderInside:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    -ms-animation-play-state: paused;
    -animation-play-state: paused;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -transform: rotate(360deg);
    }
}

.loaderInside {
    width: 100%;
    height: 50%;
    position: absolute;
    margin-top: 50%;
    background: linear-gradient(90deg, white, #cfe6e5, #bfc2c1);
}
.loaderInside:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: -50%;
    background: linear-gradient(90deg, #ff5b5b, #bfc2c1);
}
.loaderInside:after {
    content: '';
    width: 70%;
    height: 140%;
    position: absolute;
    margin-top: -35%;
    margin-left: 15%;
    background: white;
    border-radius: 50%;
}
