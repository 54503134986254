@import '/styles/config.scss';

.whiteBox {
    @include grid-item(8, 2);
    padding: scale-to-em(98);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $max-desktop) {
        // padding: scale-to-em(54);
        @include grid-item(10, 1);
    }
    @media (max-width: $max-tablet) {
        padding: scale-to-em(22);
        @include grid-item(12, 0);
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: scale-to-em(640);
    text-align: center;
    gap: scale-to-em(12);
}

.button {
    margin-top: scale-to-em(24em);
}
