@import '/styles/config.scss';
@import 'styles/config';

.smallPadd {
    padding-top: 32px !important;
    padding-bottom: 32px;
}

.text {
    color: $dark-amber;
    text-align: center;

    @include responsive-font(24, 28);

    @include grid-item(7, 2);

    @media (max-width: $max-desktop) {
        @include grid-item(10, 1);
    }

    @media (max-width: $max-tablet) {
        @include grid-item(12, 0);
    }
}
