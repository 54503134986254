@import '/styles/config.scss';

.text {
    color: $dark-amber;
    line-height: 1.4;
    @include grid-item(4, 7);
    margin-top: scale-to-em(40);
    @media (max-width: $max-desktop) {
        @include grid-item();
        margin-top: scale-to-em(10);
    }
}

.imageContainer {
    @include grid-item();
    .image {
        margin-left: calc(var(--container-gutter) * -1);
        margin-right: calc(var(--container-gutter) * -1);
        width: calc(
            100% +
                (
                    calc(
                        var(--container-gutter) * 2 -
                            var(--browser-scrollbar-width)
                    )
                )
        );
        max-width: none;
    }

    @media (min-width: $min-desktop) {
        @include grid-item(7, 0);
        direction: rtl;

        .image {
            margin: 0px;
            width: calc(100% + var(--container-gutter));
            height: auto;
            max-width: none;
            right: 0;
        }
    }
}

.imageContainerPortrait {
    @include grid-item();
    grid-row: 1 / span 3;

    @include grid-item();
    .image {
        max-height: 350px;
        margin: auto;
    }

    @media (min-width: $min-desktop) {
        @include grid-item(5, 2);
        direction: rtl;

        .image {
            margin: 0px;
            width: 100%;
            height: auto;
            max-width: none;
            max-height: none;
            right: 0;
        }
    }
}

.paragraph {
    line-height: 1.4;
    @include grid-item(4, 7);
    margin-top: scale-to-em(40);
    @media (max-width: $max-desktop) {
        @include grid-item();
        margin-top: scale-to-em(10);
    }
}
