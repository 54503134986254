@import '/styles/config.scss';
.container {
    position: relative;
}

.blobs {
    position: absolute;
    z-index: -1;
    height: 500px;
    width: 500px;
    @media (max-width: $max-mobile) {
        display: none;
    }

    pointer-events: none;

    .blob {
        filter: blur(30px);

        opacity: 0.3;
        height: 100%;
        width: 100%;
        position: relative;
        @media (max-width: $max-mobile) {
            display: none;
        }
    }
    .blob_2 {
        filter: blur(25px);

        opacity: 0.5;

        position: absolute;
        height: 70%;
        width: 70%;
        left: 20%;
        top: 20%;
        @media (max-width: $max-mobile) {
            display: none;
        }
    }
    .blob_3 {
        filter: blur(20px);

        opacity: 0.8;

        position: absolute;
        height: 40%;
        width: 40%;
        left: 40%;
        top: 40%;
    }
}

.three {
    top: -0%;
    left: -25%;
}

.four {
    top: -0%;
    right: -25%;
}
.two {
    bottom: 0%;
    right: -25%;
}

.one {
    bottom: 0%;
    left: -25%;
}

.align {
    align-self: flex-start;
}

.test {
    max-height: 500px;
}
